import * as React from "react";
import Layout from "../components/layouts/Layout";
import backgroundHex from "../assets/img/backgroundSections/background.webp";
import InventoryLayout from "../components/InventoryTractor/InventoryTractor";


// markup
const IndexPage = () => {

  if (typeof window !== 'undefined') window.location.href = "/Home"

  React.useEffect(() => {
    console.log("Starting...")
  }, [])
  

  return (
    <Layout background={backgroundHex}>
      {/*  <InventoryLayout /> */}
    </Layout>
  )
}

export default IndexPage